<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vs-table
      ref="table"
      multiple
      :sst="true"
      v-model="selected"
      :max-items="itemsPerPage"
      :data="products"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center mr-2">
          <!-- ADD NEW -->
          <router-link to="/e-commerce/products/new">
            <vs-button
              class="btn-add-new p-3 mb-4 ml-4 rounded-lg cursor-pointer flex items-center justify-center"
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">اضافة منتج</span>
            </vs-button>
          </router-link>
        </div>

        <!-- ITEMS PER PAGE -->
        <div class="flex">
          <div class="mr-3">
            <label>فلترة حسب العلامة التجارية</label>
            <v-select
              label="name"
              id="brand"
              :options="brands"
              v-model="brand"
              :reduce="(item) => item.id"
              dir="rtl"
              clearable
              @input="handleSearchAndFilter('filter')"
              class="w-full"
            ></v-select>
          </div>
          <div class="con-input-search vs-table--search mt-5">
            <input
              type="text"
              placeholder="بحث عن طريق اسم المنتج "
              class="input-search vs-table--search-input"
              v-model="skuQuery"
              @blur="handleSearchAndFilter('search')"
            />
            <i class="vs-icon notranslate icon-scale material-icons null"
              >search</i
            >
          </div>
        </div>

        <!-- Filter By Season -->

        <div class="flex">
          <label for="season-select">الفلترة بالموسم</label>
          <select
            id="season-select"
            v-model="season"
            @change="handleSearchAndFilter('filter')"
          >
            <option value="">جميع المواسم</option>
            <option value="صيفي">صيفي</option>
            <option value="شتوي">شتوي</option>
          </select>

          <div v-if="isLoading">Loading...</div>
          <div v-else-if="errorMessage">Error: {{ errorMessage }}</div>
        </div>

        <!-- End Filter By Season -->

        <!-- Filter By Size -->
        <div>
          <!-- ... -->
          <div class="con-input-search vs-table--search mt-5">
            <input
              type="text"
              placeholder="Filter by Size"
              class="input-search vs-table--search-input"
              v-model="sizeQuery"
              @blur="handleSearchAndFilterSize('filter')"
            />
          </div>
        </div>

        <!-- End Filter By Size -->
      </div>

      <template slot="thead">
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="main_category">القسم الرئيسي</vs-th>
        <vs-th sort-key="sub_category">القسم الفرعي</vs-th>

        <vs-th sort-key="price">السعر</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-category">{{ tr.sku }}</p>
            </vs-td>
            <vs-td
              class="table-image-container flex justify-center items-center"
            >
              <!-- <img :src="tr.media[0].full_path" class="table-image" /> -->
              <img
                v-if="tr.first_media"
                :src="tr.first_media.path"
                class="table-image"
              />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.title }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.category.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.sub_category.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.price }}LYD</p>
            </vs-td>

            <vs-td>
              <router-link
                :to="{
                  name: 'edit-product',
                  params: { id: tr.id },
                }"
              >
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                />
              </router-link>
              <feather-icon
                icon="ArchiveIcon"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
                class="ml-2"
                @click.stop="confirm(tr.id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
      class="mt-4"
      :total="lastPage"
      v-model="current_Page"
      goto
      @change="getData(`page=${current_Page}`, current_Page)"
    ></vs-pagination>
  </div>
</template>

<script>
import axios from "axios";
// import moduleProduct from "@/store/products/moduleProduct.js";
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      search: "",
      selected: [],
      total: 0,
      current_Page: Number(this.$route.query.page),
      itemsPerPage: 10,
      lastPage: 0,
      products: [],
      product_id: "",
      brand: "",
      season: "",
      skuQuery: null,
      duplicated_product_id: "",
      isLoading: false,
      errorMessage: null,
      selectedSeason: "all",
      sizeQuery: "",
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.products.length;
    },
    brands() {
      return this.$store.state.products.brands;
    },

    seasons() {
      return this.$store.state.products.seasons;
    },
    filteredProducts() {
      if (this.selectedSeason === "all") {
        return this.products;
      }
      return this.products.filter(
        (product) => product.season === this.selectedSeason
      );
    },
  },
  methods: {
    ...mapActions("products", [
      "changePage",
      "fetchProducts",
      "archiveProduct",
      "fetchBrands",
    ]),
    handleSearchAndFilterSize(type) {
      if (type === "filter") {
        // ...
        this.getData(`size=${this.sizeQuery}`);
        this.products = this.products.filter(
          (product) => product.size === this.sizeQuery
        );
      }
    },

    handleSearchAndFilter(indicator) {
      indicator == "search"
        ? this.skuQuery
          ? this.getData(`filter[title]=${this.skuQuery}`)
          : this.getData(`page=${this.current_Page}`)
        : this.brand
        ? this.getData(`brand=${this.brand}`)
        : this.getData(`page=${this.current_Page}`)
        ? // filter by season
          this.season
        : this.getData(`season=${this.season}`);
    },
    getData(param, page) {
      this.$vs.loading();
      let filtering_params = "";
      this.skuQuery
        ? (filtering_params = `filter[title]=${this.skuQuery}&page=${this.current_Page}`)
        : this.brand
        ? (filtering_params = `brand=${this.brand}&page=${this.current_Page}`)
        : (filtering_params = param);

      this.fetchProducts(filtering_params)
        .then((response) => {
          this.products = response.data.data.data;
          this.current_Page = response.data.data.current_page;
          this.total = response.data.data.total;
          this.lastPage = response.data.data.last_page;
          this.$vs.loading.close();

          //update the currentPage value in the state to keep track of the last page we are in
          this.changePage(page);
        })
        .catch((error) => {
          this.errorDialog(error);
          this.$vs.loading.close();
        });
    },

    confirm(id) {
      this.product_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل تريد أرشفة هذا المنتج؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    deleteData() {
      this.archiveProduct(this.product_id)
        .then(() => {
          this.getData(`page=${this.current_Page}`);
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تمت أرشفة المنتج بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },

    async fetchProductss() {
      this.isLoading = true;
      try {
        let url = "http://127.0.0.1:8000/products";
        if (this.selectedSeason !== "all") {
          url += `?season=${this.selectedSeason}`;
        }
        const response = await axios.get(url);
        this.products = response.data;
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {},
  created() {
    this.getData(`page=${this.current_Page}`);
    this.fetchBrands();
    this.fetchProducts();
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          & + i {
            left: 1rem;
          }
          &:focus + i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.table-image-container {
            height: 110px;
            // background: #fff;
            span {
              display: flex;
              justify-content: flex-start;
            }
            .table-image {
              height: 68px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>